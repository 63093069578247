// ** Tailwind Styled
import tw from "tailwind-styled-components"

interface InputProps {
  $error?: boolean
}

export const ListboxButton = tw.button<{ $error: boolean; $disabled: boolean; $open: boolean }>`
w-full focus:outline-none focus:border-secondary-100 text-left border border-neutral-40 rounded-lg relative h-[56px] w-full bg-white
${p => p.$disabled && "bg-neutral-20 "}
${p => p.$open && "border-secondary-100"}
${p => p.$error && "!border-error-100"}
`

export const StyledComboboxInput = tw.input<{ $error: boolean; $disabled: boolean; $open: boolean }>`
w-full focus:outline-none focus:border-secondary-100 text-left border border-neutral-40 rounded-lg relative h-[56px] w-full bg-white
${p => p.$disabled && "bg-neutral-20 "}
${p => p.$open && "border-secondary-100"}
${p => p.$error && "!border-error-100"}
`

export const ListboxButtonText = tw.div`
pt-6 pb-2 px-4 font-tbcx-regular text-2sm text-secondary-100
`
export const InputContainer = tw.div`
w-full text-left 
`
//  max-w-[397px]
export const InputWrapper = tw.div<{ $disabled?: boolean }>`
relative h-[56px] w-full 
${p => p.$disabled && "bg-neutral-20 rounded-lg"}

`
export const InputBase = tw.input<InputProps>`
pt-6 pb-2 px-4 focus:border-secondary-100 focus:outline-none font-tbcx-regular text-2sm border border-neutral-40 rounded-lg w-full text-secondary-100
${p => p.$error && "focus:border-error-100 border-error-100"}
`

export const InputAsDivStyled = tw.div<InputProps & { $focused?: boolean }>`
pt-6 pb-2 h-full font-tbcx-regular mobile:line-clamp-1 mobile:whitespace-pre-line text-2sm border border-neutral-40 rounded-lg px-4 w-full text-secondary-100
overflow-hidden truncate
${p => p.$error && "border-error-100"}
${p => p.$focused && "border-secondary-100"}
`

interface LabelProps {
  $focused?: boolean
  $error?: boolean
}
export const LabelStyled = tw.label<LabelProps>`
  pointer-events-none	font-tbcx-regular text-secondary-70 absolute left-4  transition-all 
    ${p => (p.$focused ? "top-2.5  text-xs" : "top-1/2 transform -translate-y-1/2 text-2sm left-4 ")}
    ${p => p.$error && "text-error-100"}
`
