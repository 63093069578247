import { useQuery } from "@tanstack/react-query"
import { QUERY_KEYS } from "src/server/queryHooks/queryKeys"
import { CacheTime } from "src/utils/configs/apiCacheTime"
import { IMainPageV2, IMainPageV3 } from "../../types/commonTypes"
import { getMainPageV2 } from "../../server/queryHooks/useIndex"
import { getMainPageV3 } from "../fetchers/publicFetchers"

//const mainPageV2Query = useMainPageV2Query()
export const useMainPageV2Query = (options?: any) => {
  return useQuery<IMainPageV2>({
    queryKey: [QUERY_KEYS.MAIN_PAGE_V2],
    queryFn: getMainPageV2,
    staleTime: CacheTime.MAIN_PAGE,
    cacheTime: CacheTime.MAIN_PAGE,
    ...options
  })
}

export const useMainPageV3Query = () => {
  return useQuery<IMainPageV3>({
    queryKey: [QUERY_KEYS.MAIN_PAGE_V3],
    queryFn: getMainPageV3,
    staleTime: CacheTime.MAIN_PAGE,
    cacheTime: CacheTime.MAIN_PAGE
  })
}
