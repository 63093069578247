import LandingService from "src/server/services/LandingService"

export const getMainPageV3 = async () => {
  try {
    const response: any = await LandingService.getMainPageV3()

    return response.data
  } catch (e: any) {
    console.error("GET MAIN PAGE DATA ERROR")
    throw e // Rethrow the error
  }
}
