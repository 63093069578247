import Cookie from "src/utils/configs/Cookie"
import HttpService from "./HttpService"

class LandingService extends HttpService {
  getMainPageData() {
    const AccessToken = Cookie.get("AccessToken")
    if (AccessToken) {
      return this.get("/natural-person/main-page")
    }
    return this.get("/public/main-page")
  }

  getMainPageV2() {
    return this.get("/public/main-page-v2")
  }

  getMainPageV3() {
    return this.get("/public/main-page-v3")
  }

  getKeywords(params: { keyword: string }) {
    return this.get("/public/keyword-search", params)
  }

  getPopularCategories() {
    return this.get("/public/popular-categories")
  }

  getTopCompanies(req?: any) {
    return this.get("/public/companies/top", {}, null, req)
  }

  getBenefits() {
    return this.get("/public/benefits")
  }
}

export default new LandingService()
