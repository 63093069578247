import { useQuery } from "@tanstack/react-query"
import LandingService from "src/server/services/LandingService"
import { CacheTime } from "src/utils/configs/apiCacheTime"
import { IPublicCategoryResponse, IMainPageV2, ICompanyResponse } from "../../types/commonTypes"
import { QUERY_KEYS } from "./queryKeys"

const useIndex = () => {
  const popularCategoriesQuery = useQuery<IPublicCategoryResponse[]>({
    queryKey: [QUERY_KEYS.PUBLIC_POPULAR_CATEGORIES],
    queryFn: fetchPopularCategories,
    staleTime: CacheTime.MAIN_PAGE,
    cacheTime: CacheTime.MAIN_PAGE
  })

  const topCompaniesQuery = useQuery<ICompanyResponse[]>({
    queryKey: [QUERY_KEYS.TOP_COMPANIES],
    queryFn: fetchTopCompanies,
    staleTime: CacheTime.MAIN_PAGE,
    cacheTime: CacheTime.MAIN_PAGE
  })

  return {
    popularCategoriesQuery,
    topCompaniesQuery
  }
}

export const fetchMainPage = async () => {
  try {
    const response: any = await LandingService.getMainPageData()

    return response.data
  } catch (e: any) {
    console.error("GET MAIN PAGE DATA ERROR")
    throw e // Rethrow the error
  }
}

export const getMainPageV2 = async () => {
  try {
    const response: any = await LandingService.getMainPageV2()

    return response.data
  } catch (e: any) {
    console.error("GET MAIN PAGE DATA ERROR")
    throw e // Rethrow the error
  }
}

export const fetchPopularCategories = async () => {
  try {
    const response: any = await LandingService.getPopularCategories()

    return response.data
  } catch (e: any) {
    console.error("GET POPULAR CATEGORIES ERROR")
    throw e // Rethrow the error
  }
}

export const getBenefits = async () => {
  try {
    const response: any = await LandingService.getBenefits()

    return response.data
  } catch (e: any) {
    console.error(e)
  }
}

export const fetchTopCompanies = async (req?: any) => {
  try {
    const response: any = await LandingService.getTopCompanies(req)

    return response.data
  } catch (e: any) {
    console.error("GET POPULAR CATEGORIES ERROR")
    throw e // Rethrow the error
  }
}

export default useIndex
